import React, {useState, useRef, useEffect} from 'react';
import styles from './BannerContainer.module.scss';

const BannerContainer = (props) => {
  return(
    <div>
      <div className={styles.BannerContainer}>
       <a href={`https://electricshuffle.com/${props.country}/london/kingscross`} target="_blank">
        <img className={styles.BannerDesk} src="./img/es_kingscross-desk.png" alt="Electric Shuffle" />
        <img className={styles.BannerMob} src="./img/es_kingscross-mob.png" alt="Electric Shuffle" />
        </a>
      </div>
    </div>
  )

};


export default BannerContainer;